import logo from './logo.svg';
import './App.css';
import EmergencyLogin from './Components/EmergencyLogin';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
    <EmergencyLogin />
    </div>
  );
}

export default App;
