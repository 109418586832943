import { useEffect, useState } from "react";
import { $AHelper } from "../Components/control/AHelper"
import konsole from "../Components/control/Konsole"
import OtherInfo from "../Components/OtherInfo"
import PersonalDetailsIcon from '../VectorPersonalDetails.png';


const CommProfSum = (props) => {
     const api = props.api

     konsole.log("apiapi",props.api)
     const userId=props.memberId
     // const spouseUserId = props.memberId == sessionStorage.getItem('spouseUserId') ? sessionStorage.getItem("SessPrimaryUserId") : sessionStorage.getItem('spouseUserId');

     const [data, setData] = useState({
        fName: '',
        mName: '',
        lName: '',
        businessName: '',
     })
     const [contact, setContact] = useState({})
     const [address, setAddress] = useState({})
     const [sameAsSpouse, setSameAsSpouse] = useState(false)
     
  

     useEffect(() => {
          const profTypeId = props.proTypeId || "";
          if (userId !== "" && profTypeId != "") {
               api?.GetSearchProfessionals(userId, profTypeId, "", "", "", "", "").then((res) => {
                    if (res.data.data) {
                         setData(res.data.data[0])
                         console.log("reserrorrrepriejfek",userId, profTypeId,res.data.data[0])   
                         
                         // if(spouseUserId && spouseUserId != "null") {
                         //      api.GetSearchProfessionals(spouseUserId, profTypeId, "", "", "", "", "").then((spouseProf) => {
                         //           if(spouseProf?.data?.data) {
                         //                const sameProf = spouseProf?.data?.data?.some(ele => ele.professionalUserId == res.data.data[0].professionalUserId);
                         //                setSameAsSpouse(sameProf)
                         //           }
                         //      }).catch((err1) => {})
                         // }

                         api.GetContactByUserid(res.data.data[0].professionalUserId).then((res) => {
                              if (res) {
                                   setContact({ contact: res.data.data.contact })
                              }
                         }).catch((error) => {
                              konsole.log(error)
                         })

                         api.getAddressByUserId(res.data.data[0].professionalUserId).then((res) => {
                              if (res.data.data ) {
                                   setAddress({ data: res.data.data.addresses[0] })

                              }
                         }).catch((error) => {
                              konsole.log("reserror",error)
                         })
 
                    }
               }).catch((error) => {
                    konsole.log(error.response)
               })
          }
     }, [userId])


     konsole.log("addressaddress",address)

     return (
          <>
            {userId && (userId != "null") && <div className="contain padding-8">
                {/* {props.profName && <ul className="pt-2 ps-3"><li className="head-2">{props.profName}</li></ul> } */}
                <div className="d-flex gap-2 pb-3 mt-3 align-items-center"> <img className="mt-0 mb-1" style={{ width: '14px', height: '14px' }} src={PersonalDetailsIcon} />
              <h5 className="healthPrimary uppercasing">{props?.userDetailOfPrimary}</h5></div>
              {console.log(data,"datadatadatadatadata")}
               {data?.professionalUserId ? <div className="row w-100">
                    <div className=" d-flex justify-content-between">
                         <div className="sumPhysician " style={{width:"170px"}}>
                              <p className="">{props.profName}’s Full Name</p>
                              <h5 className="uppercasing">{data?.fName + " " + data?.mName + " " + data?.lName}</h5>
                         </div>
                         {props.proTypeId == '11' && <div className="sumPhysician" style={{width:"120px"}}>
                              <p>Speciality</p>
                              <h5 className=""><OtherInfo  othersCategoryId={28}  othersMapNatureId={data?.proCatId}  FieldName={data?.proSubType} userId={data?.professionalUserId} /></h5>
                         </div>}
                        { props.proTypeId != '11' ? ( <div className="sumPhysician" style={{width:"120px"}}>
                              <p>Company</p>
                              <h5 className="uppercasing">{data?.businessName}</h5> 
                         </div>): ""}
                         <div className="sumPhysician" style={{width:"100px"}}>
                              <p>Suite No</p>
                              <h5>{address?.data?.addressLine2}</h5>
                         </div>
                    </div>
                    <div className="d-flex justify-content-between pt-3">
                         <div className="sumPhysician" style={{width:"170px"}}>
                              <p>Address</p>
                              <h5>{address?.data?.addressLine1}</h5>
                         </div>
                         <div className="sumPhysician" style={{width:"120px"}}>
                              <p>Street</p>
                              <h5>{address?.data?.addressLine1.substring(0, address?.data?.addressLine1?.indexOf(",")) || address?.data?.addressLine1 }</h5>
                         </div>
                         <div className="sumPhysician" style={{width:"100px"}}>
                              <p for="city">City</p>
                              <h5>{address?.data?.city}</h5>
                         </div>
                    </div>
                    <div className="d-flex justify-content-between pt-3">
                         <div className="sumPhysician" style={{width:"170px"}}>
                              <p for="state">State</p>
                              <h5>{address?.data?.state}</h5>
                         </div>
                         <div className="sumPhysician" style={{width:"120px"}}>
                              <p for="zip-code">Zip Code</p>
                              <h5>{address?.data?.zipcode}</h5>
                         </div>
                         <div className="sumPhysician" style={{width:"100px"}}>
                              <p for="county">County</p>
                              <h5>{address?.data?.county}</h5>
                         </div>
                    </div>
                    <div className="d-flex  pt-3">
                         <div className="sumPhysician" style={{width:"46%"}}>
                              <p for="county-refrence">County Reference</p>
                              <h5>{address?.data?.countyRef}</h5>
                         </div>
                         {/* {spouseUserId && spouseUserId != "null" && <div className="sumPhysician" style={{width:"54%"}}>
                              <p for="county-refrence">Same professional for spouse</p>
                              <h5>{sameAsSpouse ? "Yes" : "No"}</h5>
                         </div>} */}
                    </div>
                    <div className="d-flex pt-3">
                         <div className="sumPhysician" style={{width:"46%"}}>
                              <p>Cell Number</p>
                              <h5>{ contact?.contact?.mobiles[0]?.mobileNo ? contact?.contact?.mobiles[0]?.mobileNo.slice(0,-10)+ " "
                              + $AHelper.formatPhoneNumber(contact?.contact?.mobiles[0]?.mobileNo.slice(-10)) : null}</h5>
                         </div>
                         <div className="sumPhysician" style={{width:"54%"}}>
                              <p>Email</p>
                              <h5>{contact?.contact?.emails[0]?.emailId}</h5>
                         </div>
                    </div>
               </div> : <p className="padding-8">(Not provided)</p>}
            </div>}
          </>
     );
}

export default CommProfSum;