export const demo = true;
export const ABaseUrl = (demo) ? "https://aointakeuatapi.azurewebsites.net" : "https://aointakeapi.azurewebsites.net";

export const uscurencyFormate = (value) => {
    if(value !== undefined && value !== null && value !== ""){
      if (typeof value === 'string') {
        return parseFloat(value).toLocaleString('en-US');
      } else if (typeof value === 'number') {
        return value.toLocaleString('en-US');
      } else {
        return 'Invalid input';
      }
  
    }else{
      return ""
    }
  }
