import React, { useState, useEffect } from "react";
import { $CommonServiceFn } from "./network/Service";
import { $Service_Url } from "./network/UrlPath";
import konsole from "./control/Konsole";

const OtherInfo = ({ othersMapNatureId, FieldName, userId,othersCategoryId }) => {

  const [otherfieldname, setOtherfieldName] = useState("");
  useEffect(() => {
    if(FieldName=='Other'){
   getOtherFromApi();
    }
    return () => {};
  }, []);

  const getOtherFromApi = () => {
    let jsonobj = [{userId: userId, othersMapNatureId: othersMapNatureId,isActive: true,othersMapNature: ""}];
    konsole.log("JSONJSON",JSON.stringify(jsonobj))
    $CommonServiceFn.InvokeContractApi("POST",  $Service_Url.getOtherFromAPI,  jsonobj,  (response,err) => {
        if (response) {
          konsole.log("responseresponseresponseresponse", response);
          let responsedata=response?.data?.data
          let otherObj = responsedata?.filter(otherRes => { return otherRes?.othersCategoryId == othersCategoryId});
          console.log("otherObjotherObj",otherObj)
          let othersName= otherObj?.length > 0 ? otherObj[0].othersName : ""
          setOtherfieldName(othersName)
        }else{
            konsole.log("Error",err)
        }
      }
    );
  };
  let fieldnamewithother=(otherfieldname !=='' && otherfieldname !==undefined && otherfieldname !==null)?`${otherfieldname}`:FieldName;
  // let fieldnamewithother=(otherfieldname !=='' && otherfieldname !==undefined && otherfieldname !==null)?`${FieldName}-${otherfieldname}`:FieldName;
  konsole.log("fieldnamewithother",fieldnamewithother)
  return fieldnamewithother;
};
export default OtherInfo;
