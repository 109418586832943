import { useEffect, useState } from "react";
import Api from '../Components/control/api'
import konsole from '../Components/control/Konsole'
import { $AHelper } from "../Components/control/AHelper"

const ClientFormSumComponent = (props) => {
  const fullName =
    props.primaryCareMember.f_Name +
    " " +
    props.primaryCareMember.m_Name +
    " " +
    props.primaryCareMember.l_Name;
  const clinicName = props.primaryCareMember?.businessName || "";
  const visitDuration = props.primaryCareMember.visit_Duration;
  const is_GCM = props.primaryCareMember.is_GCM;
  konsole.log("notCErtified",is_GCM)
  const is_GCM_Certified = props.primaryCareMember.is_GCM_Certified
  konsole.log("Certified",is_GCM_Certified)
  const happy_With_Service = props.primaryCareMember.happy_With_Service;
  const docMemberUserId = props.primaryCareMember.docMemberUserId;
  const healthInsurence=props.healthInsurence

  const api = new Api();

  let [contact, setContact] = useState(null);
  let [address,setAddress] = useState(null);;

  useEffect(() => {
    api.getAddressByUserId(docMemberUserId).then((res) => {
      setAddress(res.data.data.addresses)
    }).catch(error => konsole.log("apiError20", error));
    api.GetContactByUserid(docMemberUserId).then((res) => {
      setContact(res.data.data.contact);
    }).catch(error => konsole.log("apiError21", error));
  }, []);

  return (
    <div className="contain padding-8">
      <div className="contain pt-3 pb-2">
        <div className="d-flex justify-content-between">
          <div className="sumPhysician">
            <p className="EmergencyDataUserMain">Physician Name: </p>
            <h5 className="EmergencyDataRec uppercasing">{fullName}</h5>
          </div>
          <div className="sumPhysician">
            <p className="EmergencyDataUserMain">Are you happy with his/her care?</p>
            <h5 className="EmergencyDataRec">{(typeof happy_With_Service == "boolean") && (happy_With_Service == true ? 'Yes' : 'No')}</h5>
          </div>
          <div className="sumPhysician">
            <p className="EmergencyDataUserMain">Is this physician a geriatrician?</p>
            <h5>{ (typeof is_GCM == "boolean") && (is_GCM == true ? 'Yes' : 'No')}</h5>
          </div>  
        </div>

        <div className=" d-flex gap-4 pt-3">
        {  (is_GCM == true) && <div className="sumPhysician" >
            <p className="EmergencyDataUserMain">Is this physician a 'board certified' geriatrician?</p>
            <h5 className="EmergencyDataRec">{ (typeof is_GCM_Certified == "boolean") && (is_GCM_Certified == true ? 'Yes' : 'No')}</h5>
         </div> }
         <div className="sumPhysician"></div>
         <div className="sumPhysician ps-md-4">
            <p className="EmergencyDataUserMain">For how many years have you seen this physician?</p>
            <h5 className="EmergencyDataRec">{visitDuration}</h5>
         </div>
        </div>
      </div>
                 
      <div className="contain">
          <div className="fw-bold pt-4 ">
            <h5 className='healthPrimary'>Clinic or Medical Group:</h5>
          </div>
          
          { address && address.length && <>
          <div className=" d-flex justify-content-between pt-3" style={{width:'100%'}}>
          <div className="sumPhysician">
            <p className="EmergencyDataUserMain">Clinic Name</p>
            <h5 className="EmergencyDataRec uppercasing">{clinicName}</h5>
          </div>
          <div className="sumPhysician">
          <p className="EmergencyDataUserMain">Suite No</p>
          <h5 className="EmergencyDataRec">{address[0].addressLine2}</h5>
          </div>
          <div className="sumPhysician" >
          <p className="EmergencyDataUserMain">Address</p>
          <h5 className="EmergencyDataRec">{address[0].addressLine1}</h5>
          </div>
          </div>
          <div className="d-flex justify-content-between pt-3"  style={{width:'100%'}}>
          <div className="sumPhysician" >
          <p className="EmergencyDataUserMain">Street</p>
          <h5 className="EmergencyDataRec">{address[0].addressLine1?.substring(0, address[0].addressLine1?.indexOf(",")) || address[0].addressLine1}</h5>
          </div>
            <div className="sumPhysician">
            <p className="EmergencyDataUserMain" for="city">City</p>
            <h5 className="EmergencyDataRec">{address[0].city}</h5>
            </div>
            <div className="sumPhysician" >
            <p className="EmergencyDataUserMain" for="state">State</p>
            <h5 className="EmergencyDataRec">{address[0].state}</h5>
            </div>
            </div>
            <div className="d-flex justify-content-between pt-3" style={{width:'100%'}}>
              <div className="sumPhysician" >
            <p className="EmergencyDataUserMain" for="zip-code">Zip Code</p>
            <h5 className="EmergencyDataRec">{address[0].zipcode}</h5>
            </div>
            <div className="sumPhysician">
            <p className="EmergencyDataUserMain" for="county">County</p>
            <h5 className="EmergencyDataRec">{address[0].county}</h5>
            </div>
            <div className="sumPhysician" >
            <p className="EmergencyDataUserMain" for="county-refrence">County Reference</p>
            <h5 className="EmergencyDataRec">{address[0].countyRef}</h5>
            </div>
            </div>
    
            
            </>}
      </div>

      {contact && contact.mobiles && (
        <div className="">
            <div className="d-flex justify-content-between pt-4 pb-5">
              <div className="sumPhysician" style={{width:"120px"}}>
                <p className="EmergencyDataUserMain">Cell Number</p>
                <h5 className="EmergencyDataRec">{ contact?.mobiles[0]?.mobileNo && contact?.mobiles[0]?.mobileNo.slice(0,-10)+ " " + $AHelper.formatPhoneNumber(contact?.mobiles[0]?.mobileNo.slice(-10))}</h5>
              </div>
             
              {contact && contact.emails && (
               <>
              <div className="sumPhysician ms-3" style={{width:"200px"}}>
                <p className="EmergencyDataUserMain">Email</p>
                <h5 className="EmergencyDataRec">{contact?.emails[0]?.emailId}</h5>
              </div>
              </>)}
              <div style={{width:"130px"}}>
              </div>
            </div>
         
        </div>
      )} 
    </div>
  );
};

export default ClientFormSumComponent;
