import React from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import konsole from './control/Konsole';
import { $CommonServiceFn } from './network/Service';
import { $Service_Url } from './network/UrlPath';
import { useState } from 'react';
import Emergencydata from './Emergencydata';
import loginimg from '../LoginLogo.png'
import AlertToaster from "./control/AlertToaster";
import { toast ,ToastContainer} from 'react-toastify';
// import Emergencydata from '../components/Emergencydata';

const EmergencyLogin = () => {
  const[memberId,setMemberId] = useState('')
  const[pinCode,setPincode] = useState('')
  const[Emergencycard,setemergencycard] = useState([])
  const[showPage,setShowpage] = useState(false)
  const[primaryUserId,setprimaryuserid]=useState('')

  useEffect(()=>{
let url = window.location.href?.split('?')
let cardid = url[2]?.split('=')[1]
let userId = url[1]?.split('=')[1]
setprimaryuserid(userId)
konsole.log(userId,"urlurl")
getEmergencyCard(cardid)
  },[])

  
  const getEmergencyCard = (cardid)=>{
    $CommonServiceFn.InvokeContractApi('GET', $Service_Url.getEmergencyApi + "?emergencyCardId=" + cardid, '', (response, error) => {
      if(response){
        konsole.log(response,"response")

        setemergencycard(response.data.data[0])
      }

    })
  }

  // const loginFunction = () => {
  //   konsole.log(memberId,pinCode,"memberId,pinCode")
  //   if(Emergencycard.memberId == memberId && Emergencycard.pinCode == pinCode){
  //     setShowpage(true)
  //   }
  // }


  const loginFunction = () => {
    konsole.log(memberId, pinCode, "memberId, pinCode");
  
    if (!memberId) {
      AlertToaster.error("Please fill in the Member ID");
      return;
    }
  
    if (!pinCode) {
      AlertToaster.error("Please fill in the PIN");
      return;
    }
  
    if (Emergencycard.memberId == memberId && Emergencycard.pinCode == pinCode) {
      setShowpage(true);
      AlertToaster.success("Logged In Successfully");
    } else {
      AlertToaster.error("Invalid Member ID or PIN");
    }
  };
  
  return (<>
  <ToastContainer style={{zIndex:"99999999"}} />
    {showPage==true ? <Emergencydata primaryUserId={primaryUserId} cardData={Emergencycard} /> :  <div className='EmergencyLoginMain d-flex flex-wrap'>
      <Col className='EmergencyLoginLeft d-flex flex-column d-none d-lg-block '>
        <Row>
          <Col>
            <p className="fs-3 fw-bold" style={{ color: '#720C20' }}>Member Login <>&#x2212;</></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='fs-5 fw-normal me-5 mw-75' style={{ color: '#333333' }}>Please login to access your medical details in case of emergency.</p>
          </Col>
        </Row>
      </Col>

      <Col className='EmergencyLoginRight d-flex flex-column'>
        <Row className='p-5 justify-content-center'>
          <img className='w-md-block rounded d-block'  src={loginimg} alt="Login Logo" />
        </Row>

        <Col className='EmergencyLoginLefthide justify-content-center d-flex d-block d-lg-none d-xl-none d-xxl-none pb-5'>
          <Row>
            <Col>
              <p className="fs-1 fw-bold" style={{ color: '#720C20' }}>Member Login <>&#x2212;</></p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='fs-2 fw-normal' style={{ color: '#333333' }}>Please login to access your medical details in case of emergency.</p>
            </Col>
          </Row>
        </Col>
        <Row className='m-auto d-flex flex-column'>
          <Col className='mb-4'>
            <form className='d-flex flex-column'>
            <label className='fs-5 fw-bold mb-3'>Member ID</label>
              <input style={{ background: '#E6E9EC', outline:'none' }} className='EmergencyInputLogin border border-3 rounded p-2 fs-5' type="email" id="mid" name="mid" placeholder='Enter your Member ID' value={memberId} onChange={(e)=>{setMemberId(e.target.value)}} />
            </form>
          </Col>
          <Col>
            <form className='d-flex flex-column'>
            <label className='fs-5 fw-bold mb-3'>PIN</label>
              <input style={{ background: '#E6E9EC', outline:'none' }} className='EmergencyInputLogin border border-3 rounded p-2 fs-5' type="pin" id="mpin" name="mpin" placeholder='Enter your PIN' value={pinCode} onChange={(e)=>{setPincode(e.target.value)}} />
            </form>
          </Col>
          <Col className='mt-5 pb-5'>
            <button className='EmergencyLogin-btn fs-5 rounded' onClick={()=>loginFunction()}>
                Login
            </button>
          </Col>
          {/* <Col className='my-3 text-center pb-5'>
            <p className='fs-5'>Lost your card? <a style={{ color: '#720C20' }}>Contact Us</a></p>
          </Col> */}
        </Row>
      </Col>
    </div>}
    </>
  );
};

export default EmergencyLogin;
